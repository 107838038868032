<template>
  <div class="iframe-container">
    <iframe
        class="swagger-iframe"
        allowfullscreen
        v-bind:src="apiBaseUrl"
        />
  </div>
</template>

<script>
export default {
  name: "ContractsSwagger",
  data() {
    return {
      apiBaseUrl: `${process.env.VUE_APP_GQL_ENDPOINT}`
    }
  }
}
</script>

<style>
  .swagger-iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  .iframe-container{
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
  }
</style>